<script>
	import Livechat from '$lib/Components/LiveChat/LiveChat.svelte';
	import PageMeta from '$lib/Components/PageMeta/PageMeta.svelte';
	import Title from '$lib/Components/PageTitle/PageTitle.svelte';
	import { getCookie } from '$lib/cookies';
	// import { webVitals } from '$lib/Components/Vercel/SpeedInsights/SpeedInsights.js';
	import Footer from '$lib/PageSections/Footer/Footer.svelte';
	import { getContext, onMount } from 'svelte';

	let { children } = $props();

	let userData = getContext('userData');

	onMount(() => {
		userData.signedIn = getCookie('signed_in');
	});

	// import { env } from '$env/dynamic/public';
	// const BROWSER = typeof window !== 'undefined';

	// let inject;
	// async function injectVercelAnalytics() {
	// 	inject = await (await import('@vercel/analytics')).inject;
	// 	inject({
	// 		mode: 'production',
	// 	});
	// }
	// if (BROWSER && env.VERCEL_ANALYTICS_ID) {
	// 	injectVercelAnalytics();
	// }

	// afterNavigate(() => {
	// 	if (BROWSER && env.VERCEL_ANALYTICS_ID) {
	// 		webVitals({
	// 			analyticsId: env.VERCEL_ANALYTICS_ID,
	// 		});
	// 	}
	// });
</script>

<PageMeta />

<main
	class="content-grid -:[&_h1]:justify-self-center -:[&_h1]:text-center -:[&_h2]:justify-self-center -:[&_h2]:text-center -:[&_h3]:justify-self-center -:[&_h3]:text-center -:[&_h4]:justify-self-center -:[&_h4]:text-center -:[&_h5]:justify-self-center -:[&_h5]:text-center -:[&_p]:justify-self-center -:[&_p]:text-center -:[&_section_li_p]:text-left"
>
	<Title />
	{@render children?.()}
</main>
<Footer />

<Livechat />

<style>
	main {
		height: inherit;
		margin-top: 6rem;
		flex: 1;
		place-items: normal;
		display: flex;
		flex-flow: column;
		align-items: start;
	}
	* {
		scroll-margin-top: 8rem;
	}
	@media (min-width: 768px) {
		main {
			margin-top: 0rem;
		}
		* {
			scroll-margin-top: 0;
		}
	}
</style>
